<template>
  <!--begin::Wrapper-->
  <div class="p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      novalidate="novalidate"
      @submit="onSubmitRegister"
      id="kt_login_signup_form"
      :validation-schema="registration"
    >
      <!--begin::Heading-->
      <div class="mb-10 text-center">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{$t("Création de compte utilisateur")}}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{$t("Vous avez déjà un compte ?")}}

          <router-link to="/sign-in" class="link-primary fw-bolder">
            {{$t("Se connecter")}}
          </router-link>
        </div>
        <!--end::Link-->
      </div>
      <!--end::Heading-->
      <!--begin::Action-->
      <a
          :href="returnBaseUrl()"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-light-primary w-100 mb-5 mt-2"
        >
          <img style="width: 30px" src="https://www.freeiconspng.com/thumbs/linkedin-logo-png/linkedin-logo-3.png">
          <span class="indicator-label"> {{$t("Se connecter via Linkedin")}} </span>
      </a>
      <!--end::Action-->

      <!--begin::Separator-->
      <div class="d-flex align-items-center mb-10">
        <div class="border-bottom border-gray-300 mw-50 w-100"></div>
        <span class="fw-bold text-gray-400 fs-7 mx-2">{{$t("OU")}}</span>
        <div class="border-bottom border-gray-300 mw-50 w-100"></div>
      </div>
      <!--end::Separator-->

      <!--begin::Input group-->
      <div class="row fv-row mb-7">
        <!--begin::Col-->
        <div class="col-12 col-md-6">
          <label class="form-label fw-bolder text-dark fs-6">{{$t("Prénom")}}</label>
          <Field
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder=""
            name="name"
            autocomplete="off"
            
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="name" />
            </div>
          </div>
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-12 col-md-6">
          <label class="form-label fw-bolder text-dark fs-6">{{$t("Nom")}}</label>
          <Field
            class="form-control form-control-lg form-control-solid"
            type="text"
            placeholder=""
            name="surname"
            autocomplete="off"
           
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="surname" />
            </div>
          </div>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row fv-row mb-7">
      <div class="fv-row mb-7">
        <label class="form-label fw-bolder text-dark fs-6">{{$t("Société")}}</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="societe"
          autocomplete="off"
         
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="societe" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="col-12 col-md-6">
        <label class="form-label fw-bolder text-dark fs-6">{{$t("Téléphone")}}</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          placeholder=""
          name="phone"
          autocomplete="off"
         
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="phone" />
          </div>
        </div>
      </div>  
      
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="col-12 col-md-6">
        <label class="form-label fw-bolder text-dark fs-6">{{$t("Email")}}</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="email"
          placeholder=""
          name="email"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="email" />
          </div>
        </div>
      </div>
    </div>
      <!--end::Input group-->

    <div class="row fv-row mb-7">
      <!--begin::Input group-->
      <div class="col-12 col-md-6" data-kt-password-meter="true">
        <!--begin::Wrapper-->
        <div class="mb-1">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6">{{$t("Mot de passe")}}</label>
          <!--end::Label-->

          <!--begin::Input wrapper-->
          <div class="position-relative mb-3">
            <Field
              class="form-control form-control-lg form-control-solid"
              type="password"
              placeholder=""
              name="password"
              autocomplete="off"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="password" />
              </div>
            </div>
          </div>
          <!--end::Input wrapper-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Input group--->

      <!--begin::Input group-->
      <div class="col-12 col-md-6">
        <label class="form-label fw-bolder text-dark fs-6">{{$t("Confirmer le mot de passe")}}</label>
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          placeholder=""
          name="cpassword"
          autocomplete="off"
        />
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="cpassword" />
          </div>
        </div>
      </div>
      <!--end::Input group-->
    </div>


      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <label class="form-check form-check-custom form-check-solid">
          <Field
            class="form-check-input"
            type="checkbox"
            name="toc"
            value="1"
          />
          <span class="form-check-label fw-bold text-gray-700 fs-6">
            {{$t("Vous acceptez les")}} 
            <a href="media/CGU.pdf" target="_blank" class="ms-1 link-primary">{{$t("Termes et conditions")}}</a>.
          </span>
        </label>
        
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="toc" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <button
          id="kt_sign_up_submit"
          ref="submitButton"
          type="submit"
          class="btn btn-lg btn-primary"
        >
          <span class="indicator-label"> {{$t("Créer votre compte still'Quiz")}} </span>
          <span class="indicator-progress">
            {{$t("Veuillez patienter...")}}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { use } from "element-plus/lib/locale";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {


    const store = useStore();
    const router = useRouter();
    const { t } = useI18n()

    const submitButton = ref<HTMLElement | null>(null);
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const checkPhone = (value) => {
      if (value.length === 0 || value.match(phoneRegExp)) {
        return true
      }else{
        return false
      }
    }
    const freeMails = ["advalvas", "aol", "bbox", "bluewin", "email", "emailasso", "mailexcite", "francite", "gmail", "gmx", "hushmail", "icqmail", "icloud", "iiiha", "iname", "juramail", "katamail", "laposte", "libero", "mail", "mailfence", "mailo", "mailplazza", "mixmail", "me", "myway", "no-log", "openmailbox", "orange", "outlook", "peru", "laposte", "protonmail", "radio", "safe-mail", "sfr", "softhome", "tranquille", "vmail", "vivaldi", "webmail", "webmails", "web", "yahoo", "yandex", "zoho", "caramail", "hotmail", "cyberposte", "inmano", "lavache", "mailoo", "netcourrier"]
    const checkFreeMail = (value) => {
      let isMailFree = false
      freeMails.forEach(freeMail => {
        if (value.includes(`@${freeMail}.`)) {
          isMailFree = true
        }
      })
      return !isMailFree
    }

    const registration = Yup.object().shape({
      name: Yup.string().required(t('Le prénom est requis')),
      surname: Yup.string().required(t('Le nom est requis')),
      phone: Yup.string().test(t("Numéro valide"), t("Numéro non valide"), value => value ? checkPhone(value) : true),
      email: Yup.string().min(4, t("L'email doit contenir au moins 4 caractères")).email(t("Email non valide")).required(t("L'email est requis")).test(t("Email valide"), t("Veuillez utiliser une adresse professionnelle"), value => value ? checkFreeMail(value) : true),
      password: Yup.string().min(4, t("Le mot de passe doit contenir au moins 4 caractères")).required(t("Le mot de passe est requis")),
      cpassword: Yup.string()
        .min(4, t("Le mot de passe doit contenir au moins 4 caractères"))
        .required(t("Erreur lors de la confirmation du mot de passe"))
        .oneOf([Yup.ref("password"), null], t("Le mot de passe doit correspondre")),
      toc: Yup.boolean().oneOf([true]).required(t("Veuillez accepter nos conditions")),

    });

    const returnBaseUrl = () => {
      return process.env.VUE_APP_BACK_URL+ "/connect";
    }

    const onSubmitRegister = async (values) => {
      
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      

      // Dummy delay
      setTimeout(async () => {
        // Send login request
        const newAccount = await mAxiosApi.post("/addEditUser", values);

        if(newAccount.data.results == 'error'){
          ElNotification({
            title: 'Error',
            message: newAccount.data.message,
            type: 'error',
          })
        }
        else{
          ElNotification({
            title: 'Succes',
            message: t("Votre compte a bien été crée !"),
            type: 'success',
          })
          // Go to page after successfully login
            const login = {username: values.email, password: values.password};
            //console.log(login);
              
            store.dispatch(Actions.LOGIN, login)
            .then(function () {
            router.push({ name: "board" });
  
            });
         
        }
        
         

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };


   

    return {
      registration,
      onSubmitRegister,
      submitButton,
      returnBaseUrl
    };
  },
});
</script>
